import { Suspense } from "react";
import dynamic from "next/dynamic";

import LayoutCoreProvider from "@/layouts/CoreProvider";
import LayoutMain from "@/layouts/Main";

import type { NextPageWithLayout } from "@/pages/_app";

const ViewHome = dynamic(() => import("@/views/Home"), {
  suspense: true,
  ssr: false,
});

const Home: NextPageWithLayout = () => {
  return (
    <Suspense fallback="...">
      <ViewHome />
    </Suspense>
  );
};

Home.getLayout = (page) => {
  return (
    <LayoutCoreProvider>
      <LayoutMain>{page}</LayoutMain>
    </LayoutCoreProvider>
  );
};

export default Home;
